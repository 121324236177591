<template>
  <div class="Answer">
    <!-- <div class="tabbar" :class="navBarFixed ? 'navBarFixed' : ''">
      <img @click="goback" src="../../../../static/common/logo.png" alt="" />
    </div> -->
    
      <div class="banner">
         <img class="tit" src="/subject/authentication/banner.png" alt="">
      </div>
      <div class="box1">
          <img class="tit" src="/subject/authentication/tit1.png" alt="">
        <div v-if="!StatusSuc">
        
          <input class="input1" v-model="form.Name" type="text" placeholder="请输入姓名">
          <input class="input2" v-model="form.CredentialsNum" type="text" placeholder="请输入身份证号">
          <button class="pushBtn" @click="openRedPacket"></button>
        </div>
          <div v-if="StatusSuc">
            <img class="imgSuccess" src="/subject/authentication/success.png" />
              <p class="sucp">已完成认证并领取奖励</p>
            </div>
      </div>
      <div class="box2">
          <div>
            <img class="reward" src="/subject/authentication/reward.png" />
            <p  class="yyyp">
              完成资料即入账，无附加条件
              <br />
              提交后可继续交易，盈利即可提现
            </p>
            <button class="btnTrade" @click="goTrade"></button>
          </div>
        </div>
        <div class="box3">
          <div>
             <img class="tit" src="/subject/authentication/rules.png" />
            <p class="wwwd">
              1. 完成基础认证后，即刻获得平台3美元现金+50美元 赠金奖励。
              <br />
              2. 3美元奖励为平台友情赠送，平台将为您保留3天的
              时间，3天后的23:59分，未完成平台首次入金的客户， 将无法享受这笔友情赠金。
              <br />
              3. 在3天内正常激活完成首入金后，3美元完全赠送，
              交易、提现无任何要求。如果未完成首入金，系统将 自动扣除。
              <br />
              4. 奖励变动详情请查看：
              <br />
              &nbsp;&nbsp;3美元现金：APP — 我的 — 我的资产
              <br />
              &nbsp;&nbsp;50美元赠金：App — 我的 — 赠金奖励
            </p>
          </div>
        </div>
       <p class="hhh">想要更多福利活动？</p>
        <div class="box4">
          <p>
            ·&nbsp;下载红狮官方App
            <br />
            ·&nbsp;多重新老用户福利
            <br />
            ·&nbsp;让您越交易越省钱
          </p>
          <Qrcode class="ios" :link="iosMt5DownloadUrl" :size="66"/>
          <!-- <img src="/subject/authentication/ios.png" class="ios" /> -->
          <span class="iosp">ios</span>
          <Qrcode class="and" :link="androidDownloadUrl" :size="66"/>
          <!-- <img src="/subject/authentication/and.png" class="and" /> -->
           <span class="andp">Android</span>
        </div>
        <p class="bbb">
          注意：信息收集用于保护您的账户安全，不会在未经允许下作为 其他用途
        </p>
        <loginDialog :loginDialogShow="loginDialogShow" @loginDialogShowChange="loginDialogShowChange_this($event)" />
  </div>
  
</template>

<script>
import { getAccountCompletion ,updateUserIdInfo} from '../../../../api/info';
import { getUserByToken } from '../../../../api/components/loginDialog';
import { getSessionStorage } from '../classRoom/utils/tool';
import { getUrlParam } from '../../../../utils/tools'
import loginDialog from '../components/loginDialog/wap/loginDialog.vue';
import Qrcode from '../../QrCode/index.vue'
import { mapState  } from "vuex";
export default {
  name: 'authentication',
  data() {
    return {
      loginDialogShow: false,
      StatusSuc:false,
      form:{
        Name:'',
        CredentialsNum:''
      },
      navBarFixed: false,
      // 用户账号
      sessionAccount: null,
     
      scroll: '',
      screenWidth: null,
      ttoken:''
    }
  },
  components: {
    loginDialog,
    Qrcode,
  },
  computed: {
    ...mapState('common', {
      androidDownloadUrl: 'androidDownloadUrl',
      iosMt5DownloadUrl: 'iosMt5DownloadUrl',
    })
  },
  created() {
    // 传给App.vue,不展示tabbar
    this.$emit('controlShow', true);
    var token_subject = ''
    if (getUrlParam('token_subject')) {
      token_subject = getUrlParam('token_subject')
      this.ttoken = getUrlParam('token_subject')
      getUserByToken(token_subject).then(res => {
        window.sessionStorage.setItem('account', res.data.Account)
        this.init()
      })
    } else if (getUrlParam('accessKey')) {
      token_subject = getUrlParam('accessKey')
        this.ttoken = getUrlParam('accessKey')
      getUserByToken(token_subject).then(res => {
        window.sessionStorage.setItem('account', res.data.Account)
        this.init()
      })
    } else if (window.localStorage.getItem('token_subject')) {
      token_subject = window.localStorage.getItem('token_subject')
        this.ttoken = window.localStorage.getItem('token_subject')
      getUserByToken(token_subject).then(res => {
        window.sessionStorage.setItem('account', res.data.Account)
        this.init()
      })
    } else {
      this.init()
    }
  },
  beforeMount() {
    window.addEventListener("scroll", this.handleScroll);
    this.screenWidth = document.body.clientWidth;
    
  },
  methods: {
    goTrade(){
      if(this.StatusSuc===false){
        window.scrollTo({
          top: 0,
          behavior: "smooth"
        });
        
      }else{
      const u = navigator.userAgent;
      const isAndroid = u.indexOf('Android') > -1 || u.indexOf('Adr') > -1; //android终端
      const isiOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); //ios终端
      if(isAndroid){
         window.android.gotoTrade();
      }else if(isiOS){
        window.webkit.messageHandlers.gotoTrade.postMessage("1")
      }
        
      }
      
       
    },
    openRedPacket(){
      if(this.sessionAccount===null){
         
          const u = navigator.userAgent;
          const isAndroid = u.indexOf('Android') > -1 || u.indexOf('Adr') > -1; //android终端
          const isiOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); //ios终端
          if(isAndroid){
             window.android.gotoLogin();
          }else if(isiOS){
            window.webkit.messageHandlers.gotoLogin.postMessage("1")
          }
      }else{
           const params ={
            Account :this.sessionAccount,
            Name : this.form.Name,
            CredentialsNum: this.form.CredentialsNum
          }
          updateUserIdInfo(this.ttoken,params).then(res=>{
            if(res.ok===0){
              this.$message.error(res.msg)
            }else if(res.ok===1){
              this.$message.success(res.msg)
              this.StatusSuc = true;
            }

          })
      }
     
    },
    loginDialogShowClick() {
      this.loginDialogShow = true
    },
    loginDialogShowChange_this(value) {
      this.loginDialogShow = value
    },
    init() {
      // 登录后再开始逻辑处理
      this.sessionAccount = getSessionStorage('account');
      //if (this.sessionAccount) {
      if(this.sessionAccount===null){
        //this.loginDialogShow = true

      }else{
          // 查询认证状态
      getAccountCompletion(this.ttoken).then(res => {
        if(res.data.IsBasicAuthentication===2){
          this.StatusSuc = true;
        }
        
      })
      }
    
      //}
    },
   
   
    // 返回上级页面
    goback() {
      window.location.href = window.location.origin;
    },
    // 控制顶部的定位
    handleScroll() {
      this.scroll = document.documentElement.scrollTop + document.body.scrollTop; //获取滚动后的高度
      if (this.scroll > 90) {
        this.navBarFixed = true;
      } else {
        this.navBarFixed = false;
      }
    },
  }
}
</script>

<style lang="less" scoped>
.Answer {
  width: 100%;
  height: auto;
  background: url(/subject/authentication/bg.png) no-repeat;
  background-size: 100% 100%;
  .banner{
    margin-bottom: 20px;
    img{
      display: block;
      margin: 0 auto;
      width:100%;
      height:300px
    }
  }
  .box1{
    width: 342px;
    height: 250px;
    background: url(/subject/authentication/box1.png) no-repeat;
    background-size: 100% 100%;
    margin: 0 auto;
    padding-top: 10px;
    .tit{
      display: block;
      margin: 0 auto;
      width: 180px;
      height: 30px;
    }
    .imgSuccess{
        width: 132px;
        display: block;
        margin: 0 auto;
        height: 112px;
        margin-top: 16px;
        margin-bottom: 14px;
    }
    .sucp{
      color:#fe842b;
      text-align: center;
      font-weight: bold;
    }
    .input1{
      color: #fe8367;
      width: 76%;
      border: none;
      height: 50px;
      box-sizing: border-box;
      display: block;
      margin:0 auto;
      margin-top: 20px;
      padding-left: 50px;
      background-size: 26px 26px;
      background-color: #ffe6e1;
      background-image: url(/subject/authentication/icon1.png);
      background-repeat: no-repeat;
      background-position: 10px center;
      
    }
     .input2{
      color: #fe8367;
      width: 76%;
      border: none;
      height: 50px;
      box-sizing: border-box;
      display: block;
      margin:0 auto;
      margin-top: 20px;
      padding-left: 50px;
      background-size: 26px 26px;
      background-color: #ffe6e1;
      background-image: url(/subject/authentication/icon2.png);
      background-repeat: no-repeat;
      background-position: 10px center;
    }
    .pushBtn{
      width: 225px;
      border: none;
      height: 45px;
      display:block;
      margin:0 auto;
      margin-top: 10px;
      background-size: 100% 100%;
      background-image: url(/subject/authentication/pushBtn.png);
      background-repeat: no-repeat;
      background-position: center center;
    }
  }
  .box2{
    width: 330px;
    height: 170px;
    position: relative;
    margin:0 auto;
    margin-top: 10px;
    text-align: center;
    padding-top: 30px;
    background-size: 100% 100%;
    background-image: url(/subject/authentication/box2.png);
    background-position: center center;
    .reward{
      display: block;
      margin: 0 auto;
      width:284px;
      height:85px;
    }
    .yyyp{
      color: #fe842b;
      font-size: 13px;
      text-align: left;
      padding-left: 28px;
      margin-top: 15px;
    }
    .wwwd {
    font-size: 13px;
    text-align: left;
    padding-left: 20px;
    width: 92%;
    line-height: 20px;
    margin-top:0px;
  }
  .btnTrade{
    right: 18px;
    width: 84px;
    bottom: 35px;
    height: 31px;
    border:none;
    position: absolute;
    background-size: 100% 100%;
    background-image: url(/subject/authentication/btnTrade.png);
    background-position: center center;
  }
  }
  .box3{
    width: 325px;
    height: 290px;
    margin:0 auto;
    margin-top: 20px;
    text-align: center;
    padding-top: 10px;
    background-size: 100% 100%;
    background-image: url(/subject/authentication/box3.png);
    background-position: center center;
    
    .tit{
      display: block;
      margin:0 auto;
      width:197px;
      height:33px;
      margin-bottom: 20px;
    }
    p{
      width: 90%;
      font-size: 13px;
      margin-top: 0px;
      text-align: left;
      line-height: 20px;
      padding-left: 20px;
    }
  }
  .hhh{
    color: #ffdea2;
    width: 100%;
    font-size: 17px;
    box-sizing: border-box;
    text-align: left;
    font-weight: 700;
    padding-left: 33px;
    margin-bottom: 8px;
    margin-top: 10px;
  }
  .box4{
    width: 100%;
    height: 100px;
    margin: 0;
    position: relative;
    p{
      color: #fff4e1;
      width: 100%;
      font-size: 14px;
      box-sizing: border-box;
      text-align: left;
      line-height: 20px;
      padding-left: 31px;
    }
    .ios{
      right: 110px;
      bottom: 54px;
      position: absolute;
      width: 66px;
      
    }
    .iosp{
      right: 132px;
      bottom: 35px;
      position: absolute;
      color: #fff;
    }
    .and{
      right: 30px;
      bottom: 54px;
      position: absolute;
      width: 66px;
    }
    .andp{
      right: 37px;
      bottom: 35px;
      position: absolute;
      color: #fff;
    }
  }
  .bbb{
    color: #ffb477;
    width: 80%;
    font-size: 13px;
    text-align: left;
    padding-bottom: 30px;
    padding-left: 43px;
  }
}
</style>